<template>
  <div>
    <el-dialog title="新增医院" :visible.sync="visible" @close="close">
      <el-form :model="form" :rules="rules" label-width="100px" ref="form">
        <el-form-item label="医院名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="所在地区" prop="areaId">
          <area-cascader
            :provinceId.sync="form.provinceId"
            :provinceName.sync="form.provinceName"
            :cityId.sync="form.cityId"
            :cityName.sync="form.cityName"
            :areaId.sync="form.areaId"
            :areaName.sync="form.areaName"
          />
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input
            v-model="form.address"
            placeholder="请输入详细地址"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="add">新 增</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import CustomerApi from '@/api/customer';
import AreaCascader from '@/components/common/areaCascader';

export default {
  name: 'CUSTOMER_DIALOG',
  components: {
    AreaCascader,
  },
  props: {
    dialogFormVisible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    visible: {
      get () {
        return this.dialogFormVisible;
      },
      set () {},
    },
  },
  data () {
    return {
      form: {
        address: '',
        areaId: '',
        areaName: '',
        cityId: '',
        cityName: '',
        name: '',
        provinceId: '',
        provinceName: '',
        status: 'EFFECTIVE',
      },
      rules: {
        name: [{ required: true, message: '请输入医院名称', trigger: 'blur' }],
        areaId: [{ required: true, message: '请选择地址', trigger: 'blur' }],
        address: [
          { required: true, message: '请输入详细地址', trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    close () {
      this.form.areaName = '';
      this.form.cityId = '';
      this.form.cityName = '';
      this.form.provinceId = '';
      this.form.provinceName = '';

      this.$refs.form.resetFields();

      this.$emit('update:dialogFormVisible', false);
    },
    add () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          CustomerApi.addCustomer(this.form).then(() => {
            this.$message({
              message: '新增成功',
              type: 'success',
            });

            this.$emit('update:dialogFormVisible', false);
          });
        }
      });
    },
  },
};
</script>
